import './App.css';

function App() {
  
  return (
    <div className="App" dir="rtl">
      <div className="background"></div>
     <Main />
      <div className="scroll">
      <Menu />
      </div>
    </div>
  );
}

const Main = () => {
  return (
    <div className="main">
      <h1>Adi & Tomer</h1>
      <h2 className="date">04.05.2023</h2>
    </div>
  )
}

const Menu = () => {
  return (
    <>
    <div className="card">
      <div className="header">
<h1>Adi & Tomer</h1>
      <h2>Wedding Menu</h2>
      <p>איזה כיף שהגעתם והגעתן לחגוג איתנו, <br />
        תאכלו בהנאה, אפשר לבקש ריפיל ללא הגבלה ממה שתרצו.</p>
      </div>
      
      <div className="section no-bullets">
        <h3>פותחים שולחן </h3>
        <ul>
          <li>
          לחמים מהטבון: זיתים, רוסטיק כפרי.
          </li>
          <li>
          סלטים: כפרי, עדשים, עגבניות וקלמטה, מלפפונים אינדונזי, פטריות חמות, כרובית בקראסט, 
חומוס Home made, פטה כבד בליווי ריבת שרי, קרפצ'ו בקר.
          </li>
        </ul>
      </div>
      <div className="section">
        <h3>ראשונות <span>(מוגשות לחלוקה על השולחן)</span></h3>
        <ul>
          <li>
            דג סלמון בקראסט בזיליקום על מצע פסטת פטוצ'יני
            </li>
          <li>
            קדאיף עגל עם סלט עגבניות חריף וטחינה
            </li>
          <li>
            תבשיל בשר ראש בסגנון מזרחי
            </li>
          <li>
טורטיה מגולגלת במילוי פטריות יער
          </li>
        </ul>
      </div>
      <div className="section">
        <h3>עיקריות</h3>
        <ul>
          <li>
          אסאדו בצלייה איטית בציר יין אדום בליווי פירה וירוקים
          </li>
          <li>
          סטייק אנטריקוט לצד תפו"א מדורה
          </li>
          <li>
        סטייק פרגית בסגנון אסיאתי עם אטריות אורז
          </li>
          <li>
      דואט על האש סופלקי פרגיות מן הצומח על טורטיה
      </li>
        </ul>
      </div>
      <div className="section">
        <h3>קינוחים </h3>
        <p>
        מוגשים חופשי כל הערב בויטרינה לצד עמדת הדי-ג'יי, תתפנקו.
        </p>
      </div>
      </div>
      <p className="bottom-line">בתיאבון <HeartIcon /></p>
      </>
  )
}


const HeartIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ce504b" viewBox="0 0 24 24"><path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" /></svg>;
}
export default App;
